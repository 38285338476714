var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticStyle:{"width":"100%"},attrs:{"footer-props":{
    'items-per-page-text': _vm.$t('dataTable.rowsPerPage'),
  },"headers":_vm.pollHeaders,"items":_vm.polls,"search":_vm.search,"sort-by":_vm.sort.key,"sort-desc":_vm.sort.descending,"item-key":"id","expanded":_vm.expanded,"show-expand":""},on:{"update:sortBy":function($event){return _vm.$set(_vm.sort, "key", $event)},"update:sort-by":function($event){return _vm.$set(_vm.sort, "key", $event)},"update:sortDesc":function($event){return _vm.$set(_vm.sort, "descending", $event)},"update:sort-desc":function($event){return _vm.$set(_vm.sort, "descending", $event)},"current-items":_vm.currentItems},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"table-header-search"},[_c('p',{staticClass:"text-subtitle-1 font-weight-semi-bold mb-0"},[_vm._v(" "+_vm._s(_vm.$t("polls.header"))+" ("+_vm._s(_vm.polls.length)+") ")]),_c('v-spacer'),_c('v-text-field',{staticClass:"ma-0 pa-0",attrs:{"prepend-icon":"mdi-magnify","placeholder":_vm.$t('forms.search'),"single-line":"","hide-details":"","color":"primary"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]},proxy:true},{key:"expanded-item",fn:function(ref){
  var item = ref.item;
return [_c('td',{attrs:{"colspan":_vm.pollHeaders.length}},_vm._l((item.answers),function(answer,index){return _c('tr',{key:index,attrs:{"height":"40px"}},[_c('td',[_c('span',[_vm._v(_vm._s(_vm.$t("polls.answer"))+" ")])]),_c('td',[_c('span',{staticClass:"ml-5"},[_vm._v(_vm._s(answer.value))])]),_c('td',[_c('span',{staticClass:"ml-5"},[_vm._v(_vm._s(_vm.calculateVotePercent(item, answer)))])])])}),0)]}},{key:"item.type",fn:function(ref){
  var item = ref.item;
return [_c('td',{staticClass:"tdAlign text-uppercase"},[_vm._v(" "+_vm._s(_vm.$t(("polls.pollType." + (item.options.type.toLowerCase()))))+" ")])]}},{key:"item.title",fn:function(ref){
  var item = ref.item;
return [_c('td',{staticClass:"tdAlign"},[_vm._v(" "+_vm._s(item.title)+" ")])]}},{key:"item.duration",fn:function(ref){
  var item = ref.item;
return [_c('td',{staticClass:"tdAlign"},[_vm._v(" "+_vm._s(_vm.calculateDuration(item))+" ")])]}},{key:"item.status",fn:function(ref){
  var item = ref.item;
return [_c('td',{staticClass:"tdAlign text-uppercase"},[_vm._v(" "+_vm._s(_vm.getPollStatusLabel(item.status))+" ")])]}},{key:"item.resultVisibility",fn:function(ref){
  var item = ref.item;
return [_c('td',{staticClass:"tdAlign"},[_vm._v(" "+_vm._s(_vm.getPollVisibilityLabel(item.options.resultVisibility))+" ")])]}},{key:"item.startDate",fn:function(ref){
  var item = ref.item;
return [_c('td',{staticClass:"tdAlign"},[_vm._v(" "+_vm._s(_vm.$d(new Date(item.startDate), "DateTimeShort"))+" ")])]}},{key:"item.broadcasts",fn:function(ref){
  var item = ref.item;
return [_c('td',{staticClass:"tdAlign"},[_c('ButtonMenuItemList',{attrs:{"buttonText":_vm.$t('polls.broadcasts'),"emptyText":_vm.$t('polls.all'),"items":_vm.pollBroadcasts(item)}})],1)]}},{key:"footer.prepend",fn:function(){return [_c('v-btn',{staticClass:"mr-1 brand secondary--text",attrs:{"small":"","depressed":""},on:{"click":_vm.getPollsCsv}},[_vm._v(" "+_vm._s(_vm.$t("polls.exportAll"))+" ")]),_c('v-btn',{staticClass:"mr-1 brand secondary--text",attrs:{"small":"","depressed":""},on:{"click":_vm.getPollsPageCsv}},[_vm._v(" "+_vm._s(_vm.$t("polls.exportPage"))+" ")])]},proxy:true},{key:"item.actions",fn:function(ref){
  var item = ref.item;
return [_c('v-row',{attrs:{"justify":"start","align":"center","no-gutters":""}},[(item.status !== _vm.PollStatus.ENDED)?_c('v-btn',{staticClass:"mr-2",attrs:{"color":"brand","x-small":"","dark":""}},[_c('span',{staticClass:"btn-txt",on:{"click":function($event){[_vm.PollStatus.DRAFT, _vm.PollStatus.SCHEDULED].includes(item.status)
              ? _vm.startPoll(item)
              : _vm.endPoll(item)}}},[_vm._v(" "+_vm._s(item.status === _vm.PollStatus.DRAFT ? _vm.$t("polls.start") : _vm.$t("polls.end"))+" ")])]):_vm._e(),_c('v-spacer'),(_vm.PollStatus.DRAFT === item.status)?_c('v-btn',{staticClass:"mr-2",attrs:{"icon":"","disabled":_vm.isEditingPoll},on:{"click":function($event){return _vm.setEditPoll(item)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1):_vm._e(),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.deletePoll(item.id)}}},[_c('v-icon',[_vm._v(" mdi-delete ")])],1)],1)]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }