


























import { Vue, Component, Prop } from "vue-property-decorator";

@Component({})
export default class ButtonMenuItemList extends Vue {
  @Prop({ required: true }) buttonText!: string;
  @Prop({ required: true }) emptyText!: string;
  @Prop({ required: true }) items!: string[];
}
