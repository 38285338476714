















import PageTemplate from "@/components/PageTemplate.vue";
import PollEditor from "@/components/Polls/PollEditor.vue";
import PollList from "@/components/Polls/PollList.vue";
import { RouteName } from "@/router/types";
import { Vue, Component } from "vue-property-decorator";

@Component({
  components: {
    PageTemplate,
    PollList,
    PollEditor,
  },
})
export default class Polls extends Vue {
  RouteName = RouteName;
}
