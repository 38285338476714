




































































































































































































































































































































import {
  Broadcast,
  BroadcastGetters,
} from "@/components/Broadcast/broadcast/types";
import moment from "moment";
import { Vue, Component, Ref, Watch } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import { PollActions, PollGetters } from "@/components/Polls/types";

import {
  MessageRouterPayload,
  NewPollAnswerDto,
  Poll,
  PollAnswer,
  PollDto,
  PollImage,
  PollLocation,
  PollOptions,
  PollResultVisibility,
  PollStatus,
  PollType,
  UpdatePollDto,
} from "@/services/api/poll.service.types";
import { PollDurationOption } from "./types";
import { FormRule, stringLength } from "@/helpers/form.validators";
import { VForm } from "@/types";
import copy from "fast-copy";
import { getPollVisibilityLabel, showNotification } from "@/helpers";
import {
  UpManEntityPrefix,
  UpManGeneralFile,
  UpManUseCase,
} from "@/services/api/upman.service.types";
import UpMan from "@/services/api/upman.service";
import {
  DAY_IN_MINUTES,
  HOUR_IN_MINUTES,
  WEEK_IN_MINUTES,
} from "@/helpers/consts";
import {
  CoreConfigGetters,
  TenantConfig,
  User,
  CoreUserGetters,
  UserInfo,
} from "@/spect8-core-vue/src/types";

type UploadType = "question" | "answer";
type PendingUploads = Record<number, PendingUpload>;
interface PendingUpload {
  type: UploadType;
  name: string;
  file: File;
  error: boolean;
  index: number;
}

@Component({})
export default class PollEditor extends Vue {
  // TODO: channel selection is disabled

  // Create, Update, Delete poll actions
  @Action(PollActions.CreatePoll)
  createPoll!: (data: PollDto) => Promise<Poll>;
  @Action(PollActions.UpdatePoll)
  updatePoll!: (data: UpdatePollDto) => Promise<Poll>;

  @Action(PollActions.SetEditPoll)
  setEditPoll!: (data: Poll) => Promise<void>;

  @Action(PollActions.ClearEditPoll)
  clearEditPoll!: () => Promise<void>;

  @Getter(PollGetters.EditPoll)
  editPoll!: Poll | null;

  @Getter(BroadcastGetters.Broadcasts)
  readonly broadcasts!: Broadcast[];

  @Getter(CoreConfigGetters.TenantConfig)
  readonly tenantConfig!: TenantConfig;

  @Getter(CoreUserGetters.User)
  readonly user!: User;

  @Ref("pollEditForm") form!: VForm;

  maxUploadSizeInMB = 10;
  pendingUploads: PendingUploads = {};
  uploadIndex = 0;
  saving = false;

  formValid = false;
  questionRules: FormRule[] = [stringLength(1, 100)];
  votesPerPersonRules = [this.maxVotesPerPersonRule];

  get answerRules(): FormRule[] {
    if (this.pollData.options.type === PollType.IMAGE) {
      return [stringLength(0, 25)];
    }

    return [stringLength(1, 25)];
  }

  PollType = PollType;
  pollTypes = Object.values(PollType);

  pollDurationOptions: PollDurationOption[] = [
    { label: this.$tc("polls.minutes", 5, { value: 5 }).toString(), value: 5 },
    {
      label: this.$tc("polls.minutes", 10, { value: 10 }).toString(),
      value: 10,
    },
    {
      label: this.$tc("polls.minutes", 30, { value: 30 }).toString(),
      value: 30,
    },
    {
      label: this.$tc("polls.hours", 1, { value: 1 }).toString(),
      value: HOUR_IN_MINUTES,
    },
    {
      label: this.$tc("polls.hours", 4, { value: 4 }).toString(),
      value: HOUR_IN_MINUTES * 4,
    },
    {
      label: this.$tc("polls.days", 1, { value: 1 }).toString(),
      value: DAY_IN_MINUTES,
    },
    {
      label: this.$tc("polls.weeks", 1, { value: 1 }).toString(),
      value: WEEK_IN_MINUTES,
    },
  ];
  selectedPollDuration = this.pollDurationOptions[0];
  readonly defaultPollDuration = this.pollDurationOptions[0];

  selectedBroadcasts: Broadcast[] = [];

  locationOptions = Object.values(PollLocation);
  truthyOptions = [
    {
      text: "No",
      value: false,
    },
    {
      text: "Yes",
      value: true,
    },
  ];

  pollData: Poll = {} as Poll;

  created() {
    this.pollData = this.getInitialPollData(this.defaultPollDuration);
  }

  @Watch("editPoll")
  onEditPollChange() {
    this.pollData = this.getInitialPollData(this.defaultPollDuration);
    this.selectedPollDuration =
      this.pollDurationOptions.find(
        (option) => option.value == this.editPoll?.duration
      ) || this.defaultPollDuration;

    if (!this.editPoll) {
      this.form.resetValidation(); // Ensure no validation errors have been triggered
    }
  }

  async discardPollChanges() {
    if (this.editPoll) {
      await this.clearEditPoll();
    }

    this.selectedPollDuration = this.pollDurationOptions[0];
    this.pollData = this.getInitialPollData(this.defaultPollDuration);
    this.form.resetValidation();
    this.pendingUploads = {};
    this.selectedBroadcasts = [];
  }

  getInitialPollData(defaultPollDuration: PollDurationOption): Poll {
    const pollEndDate = moment()
      .add(defaultPollDuration.value, "minutes")
      .toISOString();

    if (this.editPoll) {
      //   this.selectedChannels = [];
      this.selectedBroadcasts = this.broadcasts.filter(
        (broadcast) =>
          this.editPoll &&
          this.editPoll.messageRouterPayload.broadcastIds.includes(broadcast.id)
      );

      if (this.form) {
        this.form.resetValidation(); // Ensure no validation errors have been triggered
      }

      return {
        ...copy(this.editPoll),
        startDate: moment().toISOString(),
        endDate: pollEndDate,
      };
    }

    return {
      id: "",
      question: "",
      answers: [this.newAnswer(), this.newAnswer()],
      duration: defaultPollDuration.value,
      startDate: "",
      endDate: "",
      tenantId: this.tenantConfig.tenantId,
      messageRouterPayload: this.messageRouterPayload,
      createdByUserInfo: {} as UserInfo,
      status: PollStatus.DRAFT,
      image: null,
      options: this.pollOptionDefaults(),
      title: "",
      location: PollLocation.DEFAULT,
    };
  }

  async savePoll(): Promise<void> {
    if (!this.form.validate()) return;

    const endDate = (function ({ value }: PollDurationOption) {
      return moment().add(value, "minutes").toISOString();
    })(this.selectedPollDuration);

    const persistentOptions = {
      startDate: moment().toISOString(),
      endDate,
      messageRouterPayload: this.messageRouterPayload,
      immediate: false, // TODO: Make this an option. Currently only changes on "start"
      duration: this.selectedPollDuration.value,
    };

    const successMessage = this.pollData.id
      ? "Poll updated"
      : `You successfully added a new poll!`;

    try {
      this.saving = true;

      if (!this.pollData.id) {
        console.log(this.pollData.id);

        const savedPoll = await this.createPoll({
          ...this.pollData,
          answers: this.createNewPollAnswers(),
          ...persistentOptions,
          createdById: this.user.id,
        });

        if (!Object.values(this.pendingUploads).length) {
          showNotification(200, successMessage);
          this.discardPollChanges();
          return;
        }

        this.pollData = savedPoll;
      }

      const uploadSuccess = await this.uploadImages();

      if (uploadSuccess) {
        await this.updatePoll({
          ...this.pollData,
          ...persistentOptions,
        });

        showNotification(200, successMessage);
        this.discardPollChanges();
      }
    } catch (error) {
      // TODO: Error handling
    } finally {
      this.saving = false;
    }
  }

  get messageRouterPayload(): MessageRouterPayload {
    const broadcastIds: Set<string> = new Set();
    const channelIds: Set<string> = new Set();

    for (const broadcast of this.selectedBroadcasts) {
      broadcastIds.add(broadcast.id);
    }

    // for (const channel of this.selectedChannels) {
    //   broadcastIds.add(channel.broadcast.broadcastId);
    //   channelIds.add(channel.id);
    // }

    return {
      tenantWide: broadcastIds.size + channelIds.size === 0,
      broadcastIds: Array.from(broadcastIds),
      channelIds: Array.from(channelIds),
    };
  }

  setPollType(type: PollType): void {
    this.pollData.options.type = type;

    this.pollData.answers = this.pollData.answers.filter(
      (answer) => answer.value.length
    );

    if (type !== PollType.IMAGE) {
      this.pendingUploads = {};
    }

    switch (type) {
      case PollType.DEFAULT:
      case PollType.IMAGE:
        for (let i = 0; i <= 2 - this.pollData.answers.length; i++) {
          this.addAnswer();
        }
        break;
      case PollType.OPEN:
        this.pollData.options.votesPerPerson = 1;
        this.pollData.location = PollLocation.DEFAULT;
        break;
    }
  }

  addAnswer() {
    this.pollData.answers.push(this.newAnswer());
    this.form.resetValidation(); // Resets validation for votesPerUser and allows the user to re-trigger validation on submit / value changes
  }

  removeAnswer(index: number) {
    this.pollData.answers.splice(index, 1);
  }

  newAnswer(): PollAnswer {
    return {
      answerId: "",
      value: "",
      voteCount: 0,
      userSubmission: false,
      authorDisplayName: this.user.editRequest.displayName
        ? this.user.editRequest.displayName
        : "",
      image: null,
    };
  }

  get disabledAddAnswer() {
    return this.pollData.answers.length >= 8;
  }

  pollOptionDefaults(): PollOptions {
    return {
      type: PollType.DEFAULT,
      resultVisibility: PollResultVisibility.LIVE,
      maxCharacterCount: 25,
      showAuthors: false,
      singleWordAnswers: false,
      votesPerPerson: 1,
    };
  }

  get pollResultVisibilityItems(): {
    label: string;
    value: PollResultVisibility;
  }[] {
    const visibilityOptions = Object.values(PollResultVisibility);

    const items = visibilityOptions.map((option) => {
      return {
        label: getPollVisibilityLabel(option),
        value: option,
      };
    });

    return items;
  }

  addPollAnswerText(): string {
    if (this.pollData.options.type === PollType.OPEN) {
      return this.$i18n.t("polls.addPredefinedAnswer").toString();
    }

    if (this.pollData.options.type === PollType.IMAGE) {
      return this.$i18n.t("polls.addImageAnswer").toString();
    }

    return this.$i18n.t("polls.addAnswer").toString();
  }

  get pendingUploadCount(): number {
    return Object.values(this.pendingUploads).length;
  }

  get pendingAnswerUploadCount(): number {
    return Object.values(this.pendingUploads).filter(
      (upload) => upload.type === "answer"
    ).length;
  }

  addPendingUpload(type: UploadType, event: Event, index: number | null) {
    let target!: HTMLInputElement;

    if (event.target) {
      target = event.target as HTMLInputElement;
    }

    if (target.files?.length) {
      this.deletePendingUpload(type, index);

      this.uploadIndex++;

      Vue.set(this.pendingUploads, `upload_${this.uploadIndex}`, {
        type: type,
        name: target.files[0].name,
        file: target.files[0],
        error: !this.fileSizeValid(target.files[0]),
        index: index,
      });
    }
  }

  async uploadImages(): Promise<boolean> {
    const promises: Promise<UpManGeneralFile>[] = [];
    const promiseMap: string[] = [];

    Object.entries(this.pendingUploads).forEach(
      ([pendingUploadKey, pendingUpload]) => {
        const promise = UpMan.uploadFile({
          tenantId: this.tenantConfig.tenantId,
          entityId: UpManEntityPrefix.Poll + this.pollData.id,
          usecase: UpManUseCase.PollChoice,
          nfile: pendingUpload.file,
          isDownloadable: true,
        });
        promises.push(promise);
        promiseMap.push(pendingUploadKey);
      }
    );

    const settledPromises = await Promise.allSettled(promises);

    settledPromises.forEach((res, index) => {
      const pendingUploadKey = promiseMap[
        index
      ] as unknown as keyof PendingUploads;

      if (res.status === "rejected") {
        this.pendingUploads[pendingUploadKey].error = true;
      }

      if (res.status === "fulfilled") {
        const imageType = this.pendingUploads[pendingUploadKey].type;

        if (imageType === "question") {
          this.pollData.image = res.value;
          Vue.delete(this.pendingUploads, pendingUploadKey);
        }

        if (imageType === "answer") {
          const pendingUploadIndex =
            this.pendingUploads[pendingUploadKey].index;
          this.pollData.answers[pendingUploadIndex].image = res.value;
          Vue.delete(this.pendingUploads, pendingUploadKey);
        }
      }
    });

    if (this.pendingUploadCount) {
      this.$toast.error(this.$i18n.tc("Image upload error, please try again"), {
        timeout: 2000,
      });
    }

    return this.pendingUploadCount === 0;
  }

  createNewPollAnswers(): NewPollAnswerDto[] {
    return this.pollData.answers.map((answer: PollAnswer, index) => {
      let image: PollImage | null = null;

      if (
        this.pollData.options.type === PollType.IMAGE &&
        index < this.pendingAnswerUploadCount
      ) {
        image = {
          upmanId: "demo",
          publicPath: "",
          fileExtension: "",
          filename: "",
        };
      }

      return {
        text: answer.value,
        image: image,
      };
    });
  }

  pendingUpload(type: UploadType, index: number): PendingUpload | null {
    return (
      Object.values(this.pendingUploads).find(
        (upload) => upload.type === type && upload.index === index
      ) || null
    );
  }

  deletePendingUpload(type: UploadType, index: number | null): void {
    for (const [key, value] of Object.entries(this.pendingUploads)) {
      if (value.index === index && value.type === type) {
        Vue.delete(this.pendingUploads, key);
        break;
      }
    }
  }

  maxVotesPerPersonRule(v: number): true | string {
    return (
      v <= this.pollData.answers.length ||
      this.$t("polls.maxVotesPerPersonValidationMessage").toString()
    );
  }

  get createPollBtnDisabled() {
    return (
      this.saving ||
      !this.formValid ||
      Object.values(this.pendingUploads).some(
        (upload) => upload.error || !this.fileSizeValid(upload.file)
      )
    );
  }

  fileSizeValid(file: File): boolean {
    return file.size / 1024 / 1024 <= this.maxUploadSizeInMB;
  }

  pollLocationLabel(location: PollLocation): string {
    switch (location) {
      case PollLocation.DEFAULT:
        return this.$t("polls.locationDefault").toString();
      case PollLocation.INLINE:
        return this.$t("polls.locationInline").toString();
    }
  }
}
