






















































































































































































import {
  Broadcast,
  BroadcastGetters,
} from "@/components/Broadcast/broadcast/types";
import { Vue, Component } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import { PollActions, PollGetters, Sorting } from "@/components/Polls/types";
import {
  Poll,
  PollAnswer,
  PollExport,
  PollStartDto,
  PollStatus,
  UpdatePollDto,
} from "@/services/api/poll.service.types";
import { getPollStatusLabel, getPollVisibilityLabel } from "@/helpers";
import {
  DAY_IN_MINUTES,
  HOUR_IN_MINUTES,
  WEEK_IN_MINUTES,
} from "@/helpers/consts";
import moment from "moment";
import ButtonMenuItemList from "../ButtonMenuItemList.vue";
import { Channel } from "@/store/generalSettings/types";

@Component({
  components: {
    ButtonMenuItemList,
  },
})
export default class PollList extends Vue {
  @Getter(PollGetters.Polls)
  readonly polls!: Poll[];
  @Getter(PollGetters.EditPoll)
  readonly editPoll!: Poll | null;

  @Action(PollActions.SetEditPoll)
  setEditPoll!: (poll: Poll) => void;
  @Action(PollActions.DeletePoll)
  deletePoll!: (pollId: string) => void;
  @Action(PollActions.UpdatePoll)
  updatePoll!: (poll: UpdatePollDto) => void;
  @Action(PollActions.StartPoll)
  _startPoll!: (poll: PollStartDto) => Promise<void>;
  @Action(PollActions.EndPoll)
  _endPoll!: (pollId: string) => Promise<void>;
  @Action(PollActions.FetchPolls)
  fetchPolls!: () => Promise<void>;
  @Action(PollActions.ExportPolls)
  exportPolls!: () => Promise<PollExport>;
  @Action(PollActions.ExportPage)
  exportPollsPage!: (pollIds: Array<string>) => Promise<PollExport>;
  @Action(PollActions.SetSorting)
  setSorting!: (sorting: Sorting) => void;

  @Getter("generalSettings/allChannels")
  readonly allChannels!: Channel[];
  @Getter(BroadcastGetters.Broadcasts)
  readonly broadcasts!: Broadcast[];
  @Getter(PollGetters.Sorting)
  readonly sorting!: Sorting;

  getPollStatusLabel = getPollStatusLabel;
  getPollVisibilityLabel = getPollVisibilityLabel;
  PollStatus = PollStatus;

  expanded = [];
  search = "";
  currentSelected: Poll[] = [];

  get pollHeaders() {
    return [
      { text: this.$t("polls.status"), value: "status" },
      { text: this.$t("polls.type"), value: "type" },
      { text: this.$t("Title"), value: "title" },
      { text: this.$t("polls.question"), value: "question" },
      { text: this.$t("duration"), value: "duration" },
      { text: this.$t("polls.resultVisibility"), value: "resultVisibility" },
      { text: this.$t("polls.broadcasts"), value: "broadcasts" },
      { text: this.$t("polls.startDate"), value: "startDate" },
      {
        text: this.$t("polls.actions"),
        value: "actions",
        sortable: false,
        width: "150px",
      },
      { text: "", value: "data-table-expand" },
    ];
  }

  created(): void {
    this.fetchPolls();
  }

  pollChannels(poll: Poll) {
    return this.allChannels
      .filter((channel) =>
        poll.messageRouterPayload.channelIds.includes(channel.id)
      )
      .map((c) => c.channelName);
  }

  pollBroadcasts(poll: Poll) {
    return this.broadcasts
      .filter((broadcast) =>
        poll.messageRouterPayload.broadcastIds.includes(broadcast.id)
      )
      .map((b) => b.broadcastName);
  }

  async startPoll(poll: Poll) {
    const MINUTE_IN_MS = 1000 * 60;
    const eventDuration = poll.duration * MINUTE_IN_MS;
    const endDate = new Date(Date.now() + eventDuration);

    await this._startPoll({
      id: poll.id,
      startDate: new Date().toISOString(),
      endDate: endDate.toISOString(),
      immediate: true,
    });
  }

  async endPoll(poll: Poll) {
    await this._endPoll(poll.id);
  }

  //   getUpdatePollFromPoll(
  //     poll: Poll,
  //     startDate: string,
  //     endDate: string
  //   ): UpdatePoll {
  //     return {
  //       header: null,
  //       entries: {},
  //       pollId: poll.id,
  //       pollStartDate: startDate,
  //       pollEndDate: endDate,
  //       prepared: true,
  //       messageRouterPayload: {
  //         tenantWide: poll.messageRouterPayload.tenantWide,
  //         channelIds: poll.messageRouterPayload.channelIds,
  //         broadcastIds: poll.messageRouterPayload.broadcasters
  //           ? poll.messageRouter.broadcasters.map(b => b.id)
  //           : []
  //       }
  //     };
  //   }

  get isEditingPoll(): boolean {
    return this.editPoll ? true : false;
  }

  calculateDuration(poll: Poll): string {
    const startDate = new Date(poll.startDate);
    const endDate = new Date(poll.endDate);

    if (startDate === endDate) {
      return "∞";
    }

    const durationBreakdown = {
      weeks: Math.floor(poll.duration / WEEK_IN_MINUTES),
      days: Math.floor((poll.duration % WEEK_IN_MINUTES) / DAY_IN_MINUTES),
      hours: Math.floor(
        ((poll.duration % WEEK_IN_MINUTES) % DAY_IN_MINUTES) / HOUR_IN_MINUTES
      ),
      minutes: Math.floor(
        ((poll.duration % WEEK_IN_MINUTES) % DAY_IN_MINUTES) % HOUR_IN_MINUTES
      ),
    };

    const durationPieces: string[] = [];

    for (const [timeUnit, value] of Object.entries(durationBreakdown)) {
      if (value > 0) {
        durationPieces.push(this.$tc(`polls.${timeUnit}`, value, { value }));
        if (durationPieces.length >= 2) break;
      }
    }

    return durationPieces.join(" ");
  }

  calculateVotePercent(poll: Poll, { voteCount }: PollAnswer): string {
    const totalVotes = poll.answers.reduce(function (voteCount, currentAnswer) {
      return (voteCount += currentAnswer.voteCount);
    }, 0);

    if (!voteCount) {
      return totalVotes > 0 ? "0%" : this.$t("polls.noVotes").toString();
    }

    return voteCount * (100 / totalVotes) + "%";
  }

  currentItems(value: Poll[]) {
    this.currentSelected = value;
  }

  async getPollsCsv() {
    const csvString = await this.exportPolls();
    this.downloadCsvFile(csvString.csvString);
  }

  async getPollsPageCsv() {
    const pollIds = this.currentSelected.map((item) => item["id"]);
    const csvString = await this.exportPollsPage(pollIds);
    this.downloadCsvFile(csvString.csvString);
  }

  downloadCsvFile(csvString: string) {
    const anchor = document.createElement("a");
    anchor.href =
      "data:text/csv;charset=utf-8," + encodeURIComponent(csvString);
    anchor.target = "_blank";
    const currentDateTime = moment(new Date()).format("YYYY-MM-DD HH:mm");
    anchor.download = `${currentDateTime} poll export.csv`;
    anchor.click();
  }

  get sort() {
    return this.sorting;
  }
  set sort(sort: Sorting) {
    this.setSorting(sort);
  }
}
